import moment from "moment-timezone";
import { stringify as csvStringify } from "csv-stringify/sync";
import { convertInchesTomm, getPipeSchedule } from "../../libs/utils";
import React from "react";
function parseWeldTime(duration) {
  const hours = duration.hours() || 0;
  const minutes = duration.minutes() || 0;
  const seconds = duration.seconds() || 0;
  const hh = hours < 10 ? "0" + hours : "" + hours;
  const mm = minutes < 10 ? "0" + minutes : "" + minutes;
  const ss = seconds < 10 ? "0" + seconds : "" + seconds;

  // note - this is a duration, not a time of day
  // we cannot use moment.format("HH:mm:ss")
  // because the .format range only goes from 00 to 23 hours
  return `${hh}:${mm}:${ss}`;
}

export function generateCsvReports(robotWelds, timezone) {
  const weldData = robotWelds
    .map((item) => {
      const idx = robotWelds.findIndex((i) => i === item);

      const result = {
        ...item,
        weld_inches: item.weld_inches,
        weld_fdi: item.weld_fdi,
        weld_di: item.weld_di,
      };

      if (
        idx < robotWelds.length - 1 &&
        moment(
          moment(robotWelds[idx + 1].arc_on_utc).utc(true),
          "YYYY-MM-DD",
        ).diff(
          moment(moment(item.arc_on_utc).utc(true), "YYYY-MM-DD"),
          "hour",
        ) >= -8
      ) {
        result.duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(robotWelds[idx + 1].arc_off_utc)),
        );
      }
      return result;
    })
    .reverse();

  const headers = [
    "Date",
    "#",
    "Job Id",
    "Spool Id",
    "Weld Id",
    "Run ID",
    "Start Time",
    "Arc Off Time",
    "Arc On Time",
    "Pipe Size",
    "Pipe Sched",
    "FDI",
    "DI",
    "Operator",
    "Procedure",
  ];

  const rows = weldData.map((item, idx) => {
    const isMetric = item.units_system === "metric";
    const arcOnTime = moment.duration({ ...item.weld_time });
    return [
      moment.tz(item.arc_on_utc, timezone).format("YYYY/MM/DD"),
      idx + 1,
      item.work_order_ref,
      item.spool_ref,
      item.welder_id_ref,
      item.run_number,
      // Start Time
      moment.tz(item.arc_on_utc, timezone).format("HH:mm:ss"),
      //   arc off time - may sometimes be null for some reason
      parseWeldTime(moment.duration({ ...item.duration?._data })),
      //   arc on time
      parseWeldTime(arcOnTime),
      // pipe size
      isMetric
        ? convertInchesTomm(item.pipe_size_inches).toFixed(0) + "mm"
        : item.pipe_size_inches + "in",
      getPipeSchedule(item.pipe_schedule),
      Number(moment.duration({ ...item.weld_time }).asMinutes()) >= 1
        ? item.weld_fdi
        : "",
      Number(moment.duration({ ...item.weld_time }).asMinutes()) >= 1
        ? item.weld_di
        : "",
      item.operator_name,
      item.procedure_name,
    ];
  });

  const csvText = csvStringify([headers, ...rows]);
  // console.log("csv output", csvText)

  return csvText;
}
